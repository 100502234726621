import React from "react"
import { MDBContainer } from "mdbreact"
import SmartGreenHouseImg1 from "../images/Arhimar.jpg"
import PaginaStartEndorfina from "../images/PaginaStartEndorfina.png"
import ContactForm from "../components/ContactForm"

const Landing = () => (
  <>
    <MDBContainer className="text-center my-5 pt-5">
      <MDBContainer className="p-md-3 ">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <img src={PaginaStartEndorfina} className="img-fluid" alt="PaginaStartEndorfina" />
          </div>
        </div>
      </MDBContainer>
    </MDBContainer>
    <MDBContainer className="text-center pt-5"></MDBContainer>
    <main>
      <div className="container">
        <hr className="my-5" />
        <section className="pt-5" id="despreNoi">
          <MDBContainer className="text-center my-5">
            <h3 className="h3 text-center mb-5" >Despre Noi</h3>
            <div className="row">
              <div className="col-md-6 mb-4">
                <img
                  src={SmartGreenHouseImg1}
                  className="img-fluid z-depth-1-half"
                  alt=""
                />
              </div>
              <div className="col-md-6 mb-4">
                <p align="justify" style={{ fontSize: "15px" }}>
                  &emsp;&emsp;&emsp;Credem că <strong>fiecare proiect are o energie proprie</strong> în funcție de zona și contextul în care este realizat, de calitatea materialelor din care este compus și nu în ultimul rând, de oamenii care îl concep cu drag pentru utilizatorul final.
                  <br />
                  &emsp;&emsp;&emsp;Ne dorim ca prin <strong>colaborarea cu echipa ENDORFINA</strong>, să vă bucurați de un demers al proiectării care să vă facă plăcere, prin care să <strong>dezvoltăm și să inovăm propriul dumneavoastră obiectiv.</strong> Vă oferim oportunitatea de a colabora cu o echipă dianmică, mereu preocupată de cunoaștere și dezvoltare.
                  <br />
                  &emsp;&emsp;&emsp;Intenția noastră este să <strong>producem calitate și diversitate, atât în domeniul arhitecturii cât și în cel al urbanismului și amenajării teritoriului.</strong> Aducând împreună persoane cu valori comune, <strong>creativitatea, eficiența și seriozitatea</strong> cu care tratăm proiectele vor garanta <strong>rezultate remarcabile. </strong>
                </p>
              </div>
            </div>
          </MDBContainer>
        </section>
        <hr className="my-5" />
        <section className="pt-5" id="servicii">
          <MDBContainer className="text-center my-5">
            <h3 className="h3 text-center mb-5 ">Servicii</h3>
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 mb-4">
                <div align="justify" style={{ fontSize: "15px" }}>
                  <div className="row">
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        <strong>ARCHITECTURA (REZIDENTIAL, COMERCIAL, INDUSTRIAL)</strong>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Concept
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Arhitectură
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Ilustrare 3D
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Structură
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Instalații
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Consultanță
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Expertize tehnice
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Relevee
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Avize și acorduri
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Studii de specialitate
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Audit energetic
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Asistență tehnică
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div align="justify" style={{ fontSize: "15px" }}>
                  <div className="row">
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        <strong>URBANISM (PUD / PUZ)</strong>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Planificare urbană
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                        {" "}
                        Amenajare peisageră
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 ">
                      <i className="fas fa-arrow-right fa-1x deep-black-text" />
                    </div>
                    <div className="col-10">
                      <p className="black-text" style={{ fontSize: "15px" }}>
                      Proiectare infrastructură
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MDBContainer>
        </section>
        <hr className="my-5" />
        <section className="pt-5" id="contact">
          <MDBContainer className="text-center my-5">
            <h3 className="h3 text-center mb-5" >Formular contact</h3>
            <ContactForm />
          </MDBContainer>
        </section>
      </div>
    </main>
  </>
)

export default Landing
