import React from "react"
import { Helmet } from "react-helmet"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap-css-only/css/bootstrap.min.css"
import "mdbreact/dist/css/mdb.css"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Landing from "../components/Landing"

const IndexPage = () => (
  <div id="logo">
    <Helmet>
      <title>Endorfina</title>
      <meta
      name="Endorfina, endorfina concept, building happiness, architecture, Romania, Cluj, Cluj-Napoca, arhitectura, urbanism, design, design interior, PUD, PUZ"
      content="architecture, Romania, Cluj, Cluj-Napoca, arhitectura, urbanism, design, design interior, planificare urbana, amenajare peisagera, proiectare infrastructura, concept
      Ilustrare 3D, structura, instalatii, consultanta, expertize tehnice, relevee, studii de specialitate, avize, audit energetic, asistenta tehnica"
      />
    </Helmet>
    <Navbar />
    <Landing />
    <Footer />
  </div>
)

export default IndexPage
