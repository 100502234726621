
import React from "react";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        accept-charset="utf-8"
        action="https://formspree.io/f/mleopdbv"
        method="POST"
      >
        <div className="row justify-content-center mb-3">
          <div class="col-sm-2 text-left contactText">
            <label for="full-name text-left">Prenume Nume </label>
            <label className="text-danger"> *</label>
          </div>
          <div class="col-sm-5 text-left">
            <input class="form-control" type="text" name="Nume" id="full-name" required/>
          </div>
        </div>
        <div className="row justify-content-center contactText mb-3">
          <div class="col-sm-2 text-left">
            <label for="Email">E-mail Address </label>
            <label className="text-danger"> *</label>
          </div>
          <div class="col-sm-5 text-left">
            <input class="form-control" type="email" name="Email" id="Email" required/>
          </div>
        </div>
        <div className="row justify-content-center contactText mb-3">
          <div class="col-sm-2 text-left">
            <label for="Telefon">Telefon</label>
          </div>
          <div class="col-sm-5 text-left">
            <input class="form-control" type="telephone" name="Telefon" id="Telefon"/>
          </div>
        </div>
        <div className="row justify-content-center contactText">
          <div class="col-sm-2 text-left">
            <label for="Mesaj">Mesaj</label>
            <label className="text-danger">*</label>
          </div>
          <div class="col-sm-5 text-left">
            <textarea class="form-control" rows="6" name="Mesaj" id="Mesaj" required>
            </textarea>
          </div>
        </div>
        <input type="hidden" name="_subject" id="email-subject" value="Mesaj nou pentru Endorfina"/>
        <br/>
        {status === "SUCCESS" ? <p className="text-success" >Multumim!</p> : <button className="btn btn-grey">Trimite mesajul</button>}
        {status === "ERROR" && <p className="text-danger" >Ooops! S-a produs o eroare. Va rugam sa incercati din nou.</p>}
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}